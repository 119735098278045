import { css, createGlobalStyle } from 'styled-components';
import { media } from './utils';

import globalType from './type'
import globalColours from './colours';

const maxWidth = 1440;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
        color: black;
        font-size: 24px;
        font-family: 'Vremena', sans-serif;
    }

    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        ${globalType.body};
        font-weight: 300;
        color: inherit;
    }

    b, strong {
        font-weight: 500;
    }

    p a {
        transition: color 0.1s ease;
        cursor: pointer;

        &:hover {
            color: ${globalColours.offWhite};
        }
    }
    
    p sup {
        line-height: 0;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p:first-child {
		margin-top: 0;
	}

    /* Next Wrapper */

    #__next {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`


export const container = css`
    display: flex;
    width: 100%;
    max-width: 100vw;
    /* max-width: ${maxWidth}px; */
`

export const pagePaddingTop = css`
    padding-top: 160px;

    ${media.phone`
        padding-top: 76px;
    `}
`

export const introText = css`
    margin-bottom: 110px;

    p {
        margin-bottom: 0;
    }

    ${media.phone`
        margin-bottom: 40px;

        &, p, a {
            ${globalType.bodySmall};
        }
    `}
`

export const splitSections = css`
    display: flex;
    justify-content: space-between;
	width: 100%;
	
	> * {
		flex: 1 0 50%;
        flex-basis: calc(50% - 40px);
		position: relative;

		&:first-child {
            padding-right: 20px;

			&::before {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				width: 2px;
				background: black;
			}
		}

        &:last-child {
            padding-left: 20px;
        }

        .mobile-title {
            display: none;
        }

        ${media.tablet`
            &:first-child {
                flex-basis: calc(30% - 40px);
            }
        `}

        ${media.phone`
            &:first-child {
                display: none;
            }

            &:last-child {
                padding-left: 0;
            }

            .mobile-title {
                display: flex;
            }
        `}
	}
`

export const hoverState = css`
    transition: 0.15s opacity ease;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`

export const textHover = css`
    transition: color 0.1s ease;
    cursor: pointer;

    &:hover {
        color: ${globalColours.offWhite};
    }
`

export const padding = css`
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    ${media.tabletPortrait`
        padding-left: 24px;
        padding-right: 24px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType