import React from 'react'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import { PortalProvider } from "react-portal-hook";
import { AudioPlayerProvider } from "react-use-audio-player"

export default ({ element, props  }) => {
    return (
        <>
            <GlobalStyles/>
            <AudioPlayerProvider>
                <PortalProvider>
                    {element}
                </PortalProvider>
            </AudioPlayerProvider>
        </>
    )
}