// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-client-services-js": () => import("./../../../src/pages/client-services.js" /* webpackChunkName: "component---src-pages-client-services-js" */),
  "component---src-pages-contact-social-js": () => import("./../../../src/pages/contact-social.js" /* webpackChunkName: "component---src-pages-contact-social-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-office-expertise-js": () => import("./../../../src/pages/office-expertise.js" /* webpackChunkName: "component---src-pages-office-expertise-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-the-value-of-design-js": () => import("./../../../src/pages/the-value-of-design.js" /* webpackChunkName: "component---src-pages-the-value-of-design-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */)
}

