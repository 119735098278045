import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`

    `,
    heading2: css`

    `,
    heading3: css`

    `,
    heading4: css`

    `,
    body: css`
        font-size: 24px;
        line-height: 1.34em;
        letter-spacing: 0.04em;

        ${media.phone`
            font-size: 18px;
            line-height: 1.2em;
        `}
    `,
    bodySmall: css`
        font-size: 16px;
        line-height: 1.31em;
        letter-spacing: 0.04em;

        ${media.phone`
            font-size: 13px;
            line-height: 1.2em;
        `}
    `,
    caption: css`
        font-size: 12px;
        line-height: 1.33em;
        letter-spacing: 0.04em;
    `,
}

export default type